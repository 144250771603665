import { AlertController } from '@ionic/angular/standalone';
import { AlertOptions, ConfirmOptions, Dialog, PromptOptions, PromptResult } from '@capacitor/dialog';
import { Capacitor } from '@capacitor/core';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

/*
 * DialogService offers access to native dialogs. On web Ionic alert controller is used.
 */
export class DialogService {
    private readonly alertController: AlertController = inject(AlertController);
    private readonly translate: TranslateService = inject(TranslateService);

    /*
     * A method to display alert dialog
     */
    public async showAlert(options: AlertOptions): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            return await Dialog.alert(options);
        } else {
            let resolveFunction: () => void;
            const alert = await this.alertController.create({
                backdropDismiss: false,
                header: this.translate.instant(options.title || ''),
                message: this.translate.instant(options.message || ''),
                buttons: [
                    {
                        text: 'OK',
                        handler: () => resolveFunction()
                    }
                ]
            });
            await alert.present();
            return new Promise<void>(resolve => {
                resolveFunction = resolve;
            });
        }
    }

    /*
     * A method to display confirm dialog
     */
    public async showConfirm(options: ConfirmOptions): Promise<boolean> {
        if (Capacitor.isNativePlatform()) {
            const { value } = await Dialog.confirm({
                cancelButtonTitle: options.cancelButtonTitle
                    ? this.translate.instant(options.cancelButtonTitle)
                    : this.translate.instant('buttons_cancel'),
                okButtonTitle: options.okButtonTitle ? this.translate.instant(options.okButtonTitle) : 'OK',
                ...options
            });
            return value;
        } else {
            let resolveFunction: (confirm: boolean) => void;
            if (options.title && options.message) {
                const alert = await this.alertController.create({
                    backdropDismiss: false,
                    header: this.translate.instant(options.title),
                    message: this.translate.instant(options.message),
                    buttons: [
                        {
                            text: options.cancelButtonTitle
                                ? this.translate.instant(options.cancelButtonTitle)
                                : this.translate.instant('buttons_cancel'),
                            role: 'cancel',
                            handler: () => resolveFunction(false)
                        },
                        {
                            text: options.okButtonTitle ? this.translate.instant(options.okButtonTitle) : 'OK',
                            handler: () => resolveFunction(true)
                        }
                    ]
                });
                await alert.present();
            }
            return new Promise<boolean>(resolve => {
                resolveFunction = resolve;
            });
        }
    }

    /*
     * A method to display prompt dialog
     */
    public async showPrompt(
        options: PromptOptions,
        inputType: 'text' | 'number' | 'checkbox' = 'text'
    ): Promise<PromptResult> {
        if (Capacitor.isNativePlatform()) {
            const { value, cancelled } = await Dialog.prompt({
                title: this.translate.instant(options.title || ''),
                message: this.translate.instant(options.message),
                inputText: options.inputText ? this.translate.instant(options.inputText) : null,
                cancelButtonTitle: this.translate.instant(options.cancelButtonTitle || 'buttons_cancel')
            });
            return { value, cancelled };
        } else {
            let resolveFunction: (result: PromptResult) => PromptResult;
            const alert = await this.alertController.create({
                inputs: [
                    {
                        name: 'value',
                        type: inputType,
                        placeholder: options.inputPlaceholder ? this.translate.instant(options.inputPlaceholder) : null,
                        value: options.inputText ? this.translate.instant(options.inputText) : null,
                        label: options.inputPlaceholder ? this.translate.instant(options.inputPlaceholder) : null
                    }
                ],
                backdropDismiss: false,
                header: this.translate.instant(options.title || ''),
                message: this.translate.instant(options.message),
                buttons: [
                    {
                        text: 'OK',
                        handler: (result: PromptResult): void => {
                            // we have to parse the result here in order of the input type
                            if (inputType === 'number' || inputType === 'text') {
                                resolveFunction({
                                    value: result && result.value,
                                    cancelled: false
                                });
                            } else if (inputType === 'checkbox') {
                                let checkboxResult = result as any;
                                resolveFunction({
                                    value: result && checkboxResult[0] ? checkboxResult[0] : '',
                                    cancelled: false
                                });
                            }
                        }
                    },
                    {
                        text: this.translate.instant(options.cancelButtonTitle || 'buttons_cancel'),
                        role: 'cancel',
                        handler: () =>
                            resolveFunction({
                                value: '',
                                cancelled: true
                            })
                    }
                ]
            });
            await alert.present();
            return new Promise<PromptResult>(resolve => {
                resolveFunction = resolve as any;
            });
        }
    }
}
